import React from 'react';
import { PartnerEnum, PlacementTypeEnum } from '../models/ad';
import AdOverview from './AdOverview';
import AdSquareChamps from './../assets/icons/adSquareChamps.svg';
import AdBannerChamps from './../assets/icons/adBannerChamps.png';
import AdPrerollChamps from './../assets/icons/adPrerollChamps.svg';
import AdPaveTp from './../assets/icons/adPaveTp.svg';
import AdBannerTp from './../assets/icons/adBannerTp.svg';
import AdInterstitalTP from './../assets/icons/adInterstitialTp.svg';
import './SelectAdFormat.scss';
import { useTranslation } from 'react-i18next';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';

interface PropsSelectAdFormat {
  title?: string;
  format?: string;
  formatExample?: string;
  displayTime?: number;
  priceTitle?: string;
  media?: any;
  placementType?: PlacementTypeEnum;
  isVideo?: boolean;
  initialValue: boolean;
  disabled?: boolean;
  onClickRadio?: () => void;
  partnerColor: string;
  partner?: PartnerEnum;
}

export function SelectAdFormat(props: PropsSelectAdFormat) {
  const brandColor = props.partnerColor;
  const { t } = useTranslation();

  function handleDefaultImage() {
    if (props.isVideo) {
      return 'https://youtu.be/_shA5Xwe8_4';
    } else if (props.partner === PartnerEnum.TP) {
      if (props.placementType === PlacementTypeEnum.PERMANENT_BANNER) {
        return AdBannerTp;
      } else if (props.placementType === PlacementTypeEnum.INTERSTITIAL) {
        return AdInterstitalTP;
      } else return AdPaveTp;
    } else {
      if (props.placementType === PlacementTypeEnum.PERMANENT_BANNER) {
        return AdBannerChamps;
      } else if (props.placementType === PlacementTypeEnum.INTERSTITIAL) {
        return AdPrerollChamps;
      } else return AdSquareChamps;
    }
  }

  return (
    <div className={props.isVideo ? 'radioContainer video' : 'radioContainer'}>
      <FormControl>
        <RadioGroup
          defaultValue={PlacementTypeEnum.STATISTICS_MEDIA}
          aria-labelledby='radio-buttons-placement'
          name='radio-buttons-placement-type'
        >
          <FormControlLabel
            sx={{
              '.MuiFormControlLabel-label': {
                fontFamily: 'RobotoBold',
                fontSize: '15px',
                color: brandColor,
              },
            }}
            value={props.placementType}
            control={
              <Radio
                sx={{
                  color: brandColor,
                  '&.Mui-checked': {
                    color: brandColor,
                  },
                }}
                checked={props.initialValue}
              />
            }
            label={props.title}
            onClick={() => props.onClickRadio && props.onClickRadio()}
          />
        </RadioGroup>
      </FormControl>
      <AdOverview
        image={props.media ? props.media : handleDefaultImage()}
        placementType={props.placementType}
        isVideo={props.isVideo ? props.isVideo : false}
        partner={props.partner}
      />
      <p>
        {props.format && props.format} <br />
        {t('advert.reco-format')} :
        <br />
        {props.formatExample && props.formatExample}
      </p>
      <p>{props.displayTime && t('advert.display-duration') + ' : ' + props.displayTime + ' s'}</p>
      <p style={{ color: brandColor }}>{props.priceTitle && props.priceTitle}</p>
    </div>
  );
}

import React, { useState } from 'react';
import './NewAd.scss';
import { useNavigate } from 'react-router-dom';
import LayoutPrincipal from '../component/LayoutPrincipal';
import MainButton from '../component/MainButton';
import MainInput from '../component/MainInput';
import { handlePartnerColor } from '../helper/colorHelper';
import { IAdRedux, MediaType, PartnerEnum, PlacementTypeEnum } from '../models/ad';
import UploadMedia from '../component/UploadAd';
import { SelectAdFormat } from '../component/SelectAdFormat';
import { RootState } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import { recoverCreatedWithAmbassador, recoverDestUrl, recoverMedia, recoverType } from '../slice/CampaignSlice';
import { ITokenRedux } from '../slice/TokenSlice';
import { useUploadMediaMutation } from '../services/CampaignApi';
import { useTranslation } from 'react-i18next';
import MainCheckBox from '../component/MainCheckBox';

export default function NewAd() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const token: ITokenRedux = useSelector((state: RootState) => state.token);

  const currentAd: IAdRedux | undefined = useSelector((state: RootState) =>
    state.campaign.ads?.find((ad: IAdRedux) => ad.id === state.campaign.currentAdId),
  );

  const partner = currentAd?.partner;
  const brandColor = handlePartnerColor(partner);

  const [uploadMedia] = useUploadMediaMutation();
  const [errorMedia, setErrorMedia] = useState<string>();

  const [fileMedia, setFileMedia] = useState<string>(currentAd && currentAd.media ? currentAd.media : '');

  const [newFile, setNewFile] = useState<any>();

  const [url, setUrl] = useState<string>(currentAd && currentAd.dest_url ? currentAd.dest_url : '');

  const [createdWithAmbassador, setCreatedWithAmbassador] = useState<boolean>(
    currentAd && currentAd.created_with_ambassador ? currentAd.created_with_ambassador : false,
  );

  const [mediaType, setMediaType] = useState<MediaType | undefined>(
    currentAd && currentAd.media_type ? currentAd.media_type : undefined,
  );

  const [placementType, setPlacementType] = useState<PlacementTypeEnum | undefined>(
    currentAd && currentAd.placementType ? currentAd.placementType : undefined,
  );

  const uploadAdMedia = async (file: any, mediaType: MediaType) => {
    setErrorMedia('');
    const formData = new FormData();
    formData.append('media', file, 'testName.png');
    formData.append('mediaType', mediaType);
    formData.append('advertiserAccount', token.advertiserAccount);

    const { data, error }: any = await uploadMedia({
      form: formData,
    });
    if (data) {
      dispatch(
        recoverMedia({
          id: data.id,
          media: data.media,
          mediaType: mediaType,
        }),
      );
      setFileMedia(data.media);
    } else {
      error ? console.log('upload error', error) : console.log('no error message');
      setErrorMedia(t('error.general'));
    }
    return;
  };

  function selectPlacementType(placementType: PlacementTypeEnum, mediaType: MediaType) {
    setPlacementType(placementType);
    dispatch(recoverType(placementType));

    // TODO when the video will be used : switch between image and video
  }

  // not called for now, waiting for app to be ready
  const videoImport = (
    <>
      <article>
        <UploadMedia
          recoverFile={(fileMedia: any) => {
            setNewFile(fileMedia);
            setMediaType(MediaType.VIDEO);
            uploadAdMedia(fileMedia, MediaType.VIDEO);
          }}
          isVideo={true}
          partnerColor={brandColor}
          disabled={mediaType === MediaType.IMAGE ? true : false}
          initialValue={mediaType === MediaType.VIDEO && fileMedia ? fileMedia : undefined}
        />
      </article>

      <article className='choseFormat'>
        <SelectAdFormat
          title='Coupure pub vidéo'
          format='Format 16:9'
          displayTime={20}
          priceTitle={t('advert.10-euro-for-1000-view')}
          onClickRadio={() => {
            selectPlacementType(PlacementTypeEnum.LIVEFEED_MEDIA, MediaType.VIDEO);
          }}
          placementType={PlacementTypeEnum.LIVEFEED_MEDIA}
          isVideo={true}
          disabled={newFile && mediaType === MediaType.IMAGE ? true : false}
          initialValue={placementType === PlacementTypeEnum.LIVEFEED_MEDIA && mediaType === MediaType.VIDEO}
          media={
            newFile && mediaType === MediaType.VIDEO
              ? URL.createObjectURL(newFile)
              : fileMedia && mediaType === MediaType.VIDEO && fileMedia
          }
          partnerColor={brandColor}
          partner={partner}
        />
      </article>
    </>
  );

  return (
    <LayoutPrincipal
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
      }}
      titleStep={t('title.ad-set-visual')}
      isShowingVideoTuto={true}
    >
      <section className='importMedia'>
        <article>
          <UploadMedia
            recoverFile={(fileMedia: any) => {
              setNewFile(fileMedia);
              setMediaType(MediaType.IMAGE);
              uploadAdMedia(fileMedia, MediaType.IMAGE);
            }}
            initialValue={mediaType === MediaType.IMAGE && fileMedia ? fileMedia : undefined}
            isVideo={false}
            disabled={mediaType === MediaType.VIDEO ? true : false}
            partnerColor={brandColor}
          />
          <p style={{ color: 'grey', fontSize: '12px', margin: 0 }}>
            {t('advert.supported-format')} <br />
            {t('advert.supported-size')}
          </p>
        </article>
        <article className='choseFormat'>
          <h3 style={{ color: brandColor }}> {t('advert.choose-ad-format')} </h3>
          <div className='overview'>
            <SelectAdFormat
              format='Ratio 1:1'
              formatExample={partner === PartnerEnum.TP ? '300x250px' : '400x400px'}
              displayTime={partner === PartnerEnum.CP ? 10 : undefined}
              priceTitle={t('advert.8-euro-for-1000-view')}
              title={t(PlacementTypeEnum.STATISTICS_MEDIA)}
              onClickRadio={() => {
                selectPlacementType(PlacementTypeEnum.STATISTICS_MEDIA, MediaType.IMAGE);
              }}
              placementType={PlacementTypeEnum.STATISTICS_MEDIA}
              initialValue={placementType === PlacementTypeEnum.STATISTICS_MEDIA}
              disabled={newFile && mediaType === MediaType.VIDEO ? true : false}
              media={
                newFile && mediaType === MediaType.IMAGE
                  ? URL.createObjectURL(newFile)
                  : fileMedia && mediaType === MediaType.IMAGE && fileMedia
              }
              partnerColor={brandColor}
              partner={partner}
            />
            <SelectAdFormat
              title={t(PlacementTypeEnum.PERMANENT_BANNER)}
              format='Ratio 6:1'
              formatExample={partner === PartnerEnum.TP ? '320x50px' : '600x100px'}
              displayTime={partner === PartnerEnum.CP ? 30 : undefined}
              priceTitle={t('advert.7-euro-for-1000-view')}
              onClickRadio={() => {
                selectPlacementType(PlacementTypeEnum.PERMANENT_BANNER, MediaType.IMAGE);
              }}
              placementType={PlacementTypeEnum.PERMANENT_BANNER}
              initialValue={placementType === PlacementTypeEnum.PERMANENT_BANNER}
              disabled={newFile && mediaType === MediaType.VIDEO ? true : false}
              media={
                newFile && mediaType === MediaType.IMAGE
                  ? URL.createObjectURL(newFile)
                  : fileMedia && mediaType === MediaType.IMAGE && fileMedia
              }
              partnerColor={brandColor}
              partner={partner}
            />
            <SelectAdFormat
              title={t(PlacementTypeEnum.INTERSTITIAL)}
              format='Ratio 9:16'
              formatExample={partner === PartnerEnum.TP ? '768x1024px' : '750x1334px'}
              displayTime={5}
              priceTitle={t('advert.10-euro-for-1000-view')}
              onClickRadio={() => {
                selectPlacementType(PlacementTypeEnum.INTERSTITIAL, MediaType.IMAGE);
              }}
              placementType={PlacementTypeEnum.INTERSTITIAL}
              initialValue={placementType === PlacementTypeEnum.INTERSTITIAL}
              disabled={newFile && mediaType === MediaType.VIDEO ? true : false}
              media={
                newFile && mediaType === MediaType.IMAGE
                  ? URL.createObjectURL(newFile)
                  : fileMedia && mediaType === MediaType.IMAGE && fileMedia
              }
              partnerColor={brandColor}
              partner={partner}
            />
            {/* to activate when the app is ready for it */}
            {/* <SelectAdFormat
              title='Coupure pub fixe'
              format='Format 16:9'
              displayTime={10}
              priceTitle={t('advert.10-euro-for-1000-view')}
              onClickRadio={() => {
                selectMedia(PlacementTypeEnum.LIVEFEED_MEDIA, MediaType.IMAGE);
              }}
              placementType={PlacementTypeEnum.LIVEFEED_MEDIA}
              initialValue={placementType === PlacementTypeEnum.LIVEFEED_MEDIA && mediaType === MediaType.IMAGE}
              disabled={newFile && mediaType === MediaType.VIDEO ? true : false}
              media={
                newFile && mediaType === MediaType.IMAGE
                  ? URL.createObjectURL(newFile)
                  : fileMedia && mediaType === MediaType.IMAGE && fileMedia
              }
            /> */}
          </div>
        </article>
      </section>

      <section className='importMedia'>
        <div className='bottomContainer'>
          <h3 style={{ marginTop: 40, marginBottom: 0, color: brandColor }}>{t('advert.client-website-link')}</h3>
          {currentAd?.partner == PartnerEnum.CP && (
            <span
              style={{
                color: 'grey',
                fontSize: 10.5,
                marginBottom: 4,
              }}
            >
              {t('advert.link-not-available-on-android-tv')}
            </span>
          )}
          <MainInput
            width={'365px'}
            color={brandColor}
            isRequired={false}
            type={'url'}
            name={'lien'}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setUrl(event.target.value);
              dispatch(recoverDestUrl(event.target.value));
            }}
            value={url}
          />
          <MainCheckBox
            label={t('advert.use-an-ambasador')}
            justifyContent='flex-end'
            color={brandColor}
            initialValue={createdWithAmbassador}
            onSelectItem={(arg) => {
              setCreatedWithAmbassador(arg);
              dispatch(recoverCreatedWithAmbassador(arg));
            }}
          />
          <MainButton
            label={t('advert.next-step')}
            disabled={(!newFile && !fileMedia) || !placementType || errorMedia ? true : false}
            backgroundColor={(!newFile && !fileMedia) || !placementType ? 'grey' : brandColor}
            onClickButton={() => navigate('/parameters-ad')}
          />
          {(!newFile && !fileMedia) || !placementType ? (
            <p style={{ fontSize: '12px', color: 'grey' }}> {t('advert.import-media-and-choose-format')} </p>
          ) : null}
          {errorMedia && <p className='formError'>{errorMedia}</p>}
        </div>
      </section>
    </LayoutPrincipal>
  );
}
